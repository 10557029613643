
import { defineComponent, ref } from 'vue';

import { Modal } from 'ant-design-vue';

import MediaCards from '@/components/cards/MediaCards.vue';
import TableList from '@/components/tables/TableList.vue';
import axios from '@/utils/axios';

const columns = [
  {
    title: 'File name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 200,
  },
  {
    title: 'Video',
    dataIndex: 'mediaUrl',
    isList: true,
    key: 'mediaUrl',
  },
  {
    title: 'Scenario',
    dataIndex: 'scenario',
    key: 'scenario',
  },
];

export default defineComponent({
  name: 'UploadImage',
  components: {
    MediaCards,
    TableList,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
    scenarioUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const mediaCardRef = ref();
    const tableListRef = ref();

    const submit = async () => {
      const { newFaces, oldFaces, video, audio, scenario } =
        await mediaCardRef.value.submit();

      const { status } = await axios.post(props.apiUrl, {
        name: video[0].uid,
        videoId: video[0].uid,
        audioId: audio[0].uid,
        scenarioId: scenario.uid,
        videoFaceIds: oldFaces.map((face: { id: any }) => face.id),
        imageFaceIds: newFaces.map((face: { id: any }) => face.id),
      });
      if (200 <= status && status < 300) {
        Modal.info({
          title: '요청 성공',
          content: '요청 성공했어요. 조금만 기다려보세요',
        });
        tableListRef.value.getApiData();
      } else {
        Modal.error({
          title: '요청 실패',
          content: '다시 한번 요청해보세요',
        });
      }
    };

    return {
      columns,
      //state
      mediaCardRef,
      tableListRef,

      // func
      submit,
    };
  },
});
